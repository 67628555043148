<template>
  <p>Register for the Event</p>
  <button @click="register">Register Me</button>
</template>

<script>
export default {
  name: 'EventDetails',

  props: ['event'],

  inject: ['GStore'],

  methods: {
    register() {
      this.GStore.flashMessage = 
        `You are successfully registred for ${this.event.title}`
        setTimeout(() => {
          this.GStore.flashMessage = ''
        }, 3000)
      this.$router.push({
        name: 'EventDetails'
      })

    }
  }
}
</script>
