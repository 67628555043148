<template>
  <p>Edit the Event</p>
</template>

<script>
export default {
  name: 'EventDetails',

  props: ['event']
}
</script>
